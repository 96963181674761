<template>
    <teleport to="#root-bg">
      <div class="screenfill fade" :class="showclass">
        <slot />
      </div>
    </teleport>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import {curr_bg} from "../globals";

export default defineComponent({
  name: "BgGoblin",
  props: {
    id: {
      type: Number,
      required: true 
    }
  },
  setup(props) {
    const on = computed(() => props.id == curr_bg.value);

    const showclass = computed(() => on.value ? ["show"] : ["hide"]);

    return {
      on,
      showclass
    };
  }
});
</script>

<style lang="scss" scoped>
  .fade {
    transition: opacity linear .6s;
  }

  .show {
    opacity: 1;

  }

  .hide {
    opacity: 0;
  }
</style>