
import { defineComponent } from "vue";
import Category from "./Category.vue";
import BgGoblin from "./BgGoblin.vue";

export default defineComponent({
  name: "HelloWorld",
  props: {},
  components: {
    Category,
    BgGoblin
  }
});
