<template>
  <div
    class="hovercat flexrow"
    @mouseover="hover_enter"
    @mouseleave="hover_exit"
  >
    <slot />

    <BgGoblin :id="cat_id">
        <slot name="bg"> </slot>
    </BgGoblin>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import BgGoblin from "./BgGoblin.vue";
import { curr_bg } from "../globals";

export default defineComponent({
  name: "Category",
  components: {
    BgGoblin
  },
  props: {},
  setup() {
    const cat_id = Math.round(100000 * Math.random());

    const hover_enter = () => {
      curr_bg.value = cat_id;
    };

    const hover_exit = () => {
      curr_bg.value = 0;
    };

    return {
      cat_id,
      hover_enter,
      hover_exit
    };
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.hovercat {
  border-width: 3px;
  border-style: solid;
  margin-top: 20px;
  font-size: 15px;
  padding: 25px;
  border-radius: 25px;
  border-color: #42b983;
  background-color: #0c2419;
}

.hovercat > * {
  padding-inline: 6px;
}

</style>
