<template>
  <div class="flexcol">
    <h1 class="greeting">WELCOME TO HELL</h1>
    <h3>Directory</h3>

      <Category>
        <a href="/elysium" target="_blank" rel="noopener">
          Elysium
        </a>

        <a href="/elysiumfiles" target="_blank" rel="noopener">
          Elysium Files
        </a>

        <template #bg>
          <div class="screenfill elysium" />
        </template>
      </Category>

      <Category>
        <a href="/tartarus" target="_blank" rel="noopener">
          Tartarus
        </a>
        <a href="/tartarusfiles" target="_blank" rel="noopener">
          Tartarus Files
        </a>

        <template #bg>
          <div class="screenfill tartarus" />
        </template>
      </Category>

      <Category>
        <a href="/asphodel" target="_blank" rel="noopener">
          Asphodel
        </a>
        <a href="/asphodelfiles" target="_blank" rel="noopener">
          Asphodel Files
        </a>

        <template #bg>
          <div class="screenfill asphodel" />
        </template>
      </Category>

      <Category>
        <a href="/cloudbank" target="_blank" rel="noopener">
          Cloudbank
        </a>
        <a href="/cloudbankfiles" target="_blank" rel="noopener">
          Cloudbank Files
        </a>

        <template #bg>
          <div class="screenfill cloudbank" />
        </template>
      </Category>

      <Category>
        <a href="/sprout/charts/hour" target="_blank" rel="noopener">
          Sprout Hour Chart
        </a>
        <a href="/sprout/charts/day" target="_blank" rel="noopener">
          Sprout Day Chart
        </a>
        <a href="/sprout/charts/week" target="_blank" rel="noopener">
          Sprout Week Chart
        </a>
        <a href="/sprout/state" target="_blank" rel="noopener">
          Sprout State
        </a>
        <a href="/sproutfiles" target="_blank" rel="noopener">
          Sprout Files
        </a>

        <template #bg>
          <video autoplay muted loop v-show="true" class="widthfill">
            <source src="../assets/gecc.webm" />
          </video>
        </template>
      </Category>
      <BgGoblin :id="0"> 
        <div class="screenfill no-bg">
        </div>
      </BgGoblin>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Category from "./Category.vue";
import BgGoblin from "./BgGoblin.vue";

export default defineComponent({
  name: "HelloWorld",
  props: {},
  components: {
    Category,
    BgGoblin
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
h3 {
  margin: 40px 0 0;
}

a {
  color: #42b983;
}

$x: 4px;
$y: 6px;
$color1: #ff00ff;
$color2: #00ffff;
$static2: "https://64.media.tumblr.com/23a0c8d9069318b8980122000578fd46/tumblr_nouc0pG3Fz1qedb29o1_500.gifv";
$static: "https://media0.giphy.com/media/1lALzcU4pUHWWMGTlK/giphy.gif?cid=ecf05e47af893ed3fd2c483829e541ff5aca24c0327bb9e9&rid=giphy.gif";

/* Welcome to hell! */
.greeting {
  font-size: 6em;
  box-shadow: none;
  color: white;
  font-style: normal;
  letter-spacing: 0.05em;
  border-radius: 0;
  animation: 4s ease 0s infinite alternate-reverse distort3;

  /* Grain */
  background-size: 30%;
  background-image: url($static);
  background-clip: text;
  -webkit-background-clip: text;
}

@keyframes distort3 {
  20% {
    color: #ffffff80;
  }
  40% {
    color: #ffffff10;
  }
  60% {
    color: #ffffffd0;
  }
  80% {
    color: #ffffff00;
  }
  100% {
    color: #ffffffff;
  }
}

.tartarus {
  background-size: cover;
  background-image: url("../assets/supergiant_tartarus.jpg");
}

.elysium {
  background-size: cover;
  background-image: url("../assets/supergiant_elysium.jpg");
}

.asphodel {
  background-size: cover;
  background-image: url("../assets/supergiant_asphodel.jpg");
}

.cloudbank {
  background-size: cover;
  background-image: url("../assets/supergiant_cloudbank.jpg");
}

.no-bg {
  background-color: #091622;
}
</style>
