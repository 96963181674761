
import { defineComponent, ref } from "vue";
import BgGoblin from "./BgGoblin.vue";
import { curr_bg } from "../globals";

export default defineComponent({
  name: "Category",
  components: {
    BgGoblin
  },
  props: {},
  setup() {
    const cat_id = Math.round(100000 * Math.random());

    const hover_enter = () => {
      curr_bg.value = cat_id;
    };

    const hover_exit = () => {
      curr_bg.value = 0;
    };

    return {
      cat_id,
      hover_enter,
      hover_exit
    };
  }
});
